import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import '../styles/selection-screen.css';

// Improved type definitions with more explicit typing
interface PokemonStats {
    hp: number;
    attack: number;
    defense: number;
    speed: number;
}

interface EffectivenessDetail {
    strong: string[];
    weak: string[];
    immune: string[];
}

interface PokemonEffectiveness {
    offensive: EffectivenessDetail;
    defensive: EffectivenessDetail;
}

interface Move {
    name: string;
    type?: string;
    power?: number;
    accuracy?: number;
    description?: string;
}

interface Pokemon {
    id: number;
    name: string;
    stats: Partial<PokemonStats>;
    types: string[];
    image_url: string;
    back_image_url: string;
    moves: Move[]; // An array of Move objects
    selectedMoves?: string[];
    effectiveness?: PokemonEffectiveness;
}

const BASE_URL = "https://pokemon-game-backend-production.up.railway.app";
// const BASE_URL = "https://localhost:5000";

const CONFIG = {
    BASE_URL,
    FRONT_SPRITE_URL: `${BASE_URL}/sprites/`,
    BACK_SPRITE_URL: `${BASE_URL}/sprites/back/`,
    API_KEY: process.env.REACT_APP_API_KEY || "default_api_key_if_missing",
    FALLBACK_IMAGE: "https://via.placeholder.com/120?text=No+Image"
} as const;

const GENERATION_RANGES: Record<number, [number, number]> = {
    1: [1, 151],
    2: [152, 251],
    3: [252, 386],
    4: [387, 493],
    5: [494, 649],
    6: [650, 721],
    7: [722, 809],
    8: [810, 890],
};

const SelectionScreen: React.FC = () => {
    const [pokemonList, setPokemonList] = useState<Pokemon[]>([]);
    const [filteredPokemonList, setFilteredPokemonList] = useState<Pokemon[]>([]);
    const [selectedGeneration, setSelectedGeneration] = useState<number>(1);
    const [loading, setLoading] = useState<boolean>(true);
    const [selectedPokemon, setSelectedPokemon] = useState<Pokemon | null>(null);
    const [selectedMoves, setSelectedMoves] = useState<Move[]>([]);
    const [showConfirmPopup, setShowConfirmPopup] = useState<boolean>(false);
    const [isLoadingMoves, setIsLoadingMoves] = useState<boolean>(false);

    const navigate = useNavigate();

    // Utility function to normalize Pokémon names
    const normalizePokemonName = (name: string): string => {
        return name
            .toLowerCase()
            .trim()
            .replace(/\s+/g, '')
            .replace(/[^a-z0-9-]/g, '');
    };

    // Fetch Pokémon data
    useEffect(() => {
        const fetchPokemonData = async () => {
            setLoading(true);
            try {
                const response = await axios.get(`${CONFIG.BASE_URL}/api/pokemon`, {
                    headers: { 'x-api-key': CONFIG.API_KEY },
                });
        
                const processedData = response.data.map((pokemon: any) => ({
                    id: pokemon.id,
                    name: pokemon.name || `unknown_${pokemon.id}`,
                    stats: pokemon.stats || { hp: "N/A", attack: "N/A", defense: "N/A", speed: "N/A" },
                    types: pokemon.types || ["Unknown"],
                    image_url: `${CONFIG.FRONT_SPRITE_URL}${pokemon.id}.png`,
                    back_image_url: `${CONFIG.BACK_SPRITE_URL}${pokemon.id}.png`,
                    moves: pokemon.moves || [],
                }));
        
                console.log(`[DEBUG] Processed Pokémon List:`, processedData); // Add this log
                setPokemonList(processedData);
            } catch (error: any) {
                console.error('[ERROR] Fetching Pokémon data:', error.response?.data || error.message);
            } finally {
                setLoading(false);
            }
        };

        fetchPokemonData();
    }, []);

    // Filter Pokémon based on generation
    useEffect(() => {
        const range = GENERATION_RANGES[selectedGeneration];
        const filtered = pokemonList.filter(
            (pokemon) => pokemon.id >= range[0] && pokemon.id <= range[1]
        );
        setFilteredPokemonList(filtered);
    }, [selectedGeneration, pokemonList]);

    const fetchTypeEffectiveness = async (pokemon: Pokemon) => {
        const normalizedPokemonName = normalizePokemonName(pokemon.name);
    
        if (!normalizedPokemonName || normalizedPokemonName === "unknown") {
            console.warn(`[WARNING] Skipping type effectiveness fetch for invalid Pokémon name: "${pokemon.name}"`);
            return {
                defensive: { strong: [], weak: [], immune: [] },
                offensive: { strong: [], weak: [], immune: [] },
            };
        }
    
        try {
            const response = await axios.get(
                `${CONFIG.BASE_URL}/api/pokemon/type-effectiveness/${normalizedPokemonName}`,
                { headers: { 'x-api-key': CONFIG.API_KEY } }
            );
            return response.data || {
                defensive: { strong: [], weak: [], immune: [] },
                offensive: { strong: [], weak: [], immune: [] },
            };
        } catch (error) {
            console.error('[ERROR] Fetching type effectiveness failed:');
            return {
                defensive: { strong: [], weak: [], immune: [] },
                offensive: { strong: [], weak: [], immune: [] },
            };
        }
    };

    const fetchPokemonDetails = async (pokemon: Pokemon) => {
        try {
            const normalizedPokemonName = normalizePokemonName(pokemon.name);
    
            // Fetch data from the backend
            const [movesResponse, statsResponse, effectiveness] = await Promise.all([
                axios.get(`${CONFIG.BASE_URL}/api/pokemon/moves/${normalizedPokemonName}`, {
                    headers: { 'x-api-key': CONFIG.API_KEY },
                }),
                axios.get(`${CONFIG.BASE_URL}/api/pokemon/base-stats/${pokemon.id}`, {
                    headers: { 'x-api-key': CONFIG.API_KEY },
                }),
                fetchTypeEffectiveness(pokemon), // Pass the entire Pokémon object
            ]);
    
            // Log the responses for debugging
            console.log('Moves Response:', movesResponse.data);
            console.log('Stats Response:', statsResponse.data);
            console.log('Effectiveness Response:', effectiveness);
    
            // Use resolved name from the backend
            const resolvedName = movesResponse.data.resolvedName || pokemon.name;
    
            // Update Pokémon name to the resolved name
            pokemon.name = resolvedName;
    
            // Structured data to return
            const result = {
                moves: movesResponse.data.moves.length > 0
                    ? movesResponse.data.moves // Full move details from the backend
                    : ["No valid moves found"],
                stats: statsResponse.data, // Stats fetched from the backend
                effectiveness, // Now includes both defensive and offensive
                resolvedName, // Updated Pokémon name
            };
    
            console.log('Processed Result:', result); // Log the final processed data
            return result;
        } catch (error: any) {
            console.error('Error fetching Pokémon details:', error.message);
    
            // Return fallback data in case of errors
            return {
                moves: [],
                stats: {}, // Default stats
                effectiveness: {
                    defensive: { strong: [], weak: [], immune: [] },
                    offensive: { strong: [], weak: [], immune: [] },
                },
                resolvedName: pokemon.name, // Fallback to the original name
            };
        }
    };    

    const handleSelectPokemon = async (pokemon: Pokemon) => {
        const fixedPokemon: Pokemon = {
            ...pokemon,
            name: pokemon.name?.toLowerCase() || `unknown_${pokemon.id}`,
        };
    
        setSelectedPokemon({
            ...fixedPokemon,
            moves: [],
            stats: {},
            effectiveness: {
                defensive: { strong: [], weak: [], immune: [] },
                offensive: { strong: [], weak: [], immune: [] },
            },
        });
        setShowConfirmPopup(true);
        setIsLoadingMoves(true);
        setSelectedMoves([]);
    
        try {
            const details = await fetchPokemonDetails(fixedPokemon);
    
            // Explicitly assert the type of details.moves
            const normalizedMoves: Move[] = (details.moves as Array<string | Move>).map((move) =>
                typeof move === "string"
                    ? { name: move, type: "Unknown", power: undefined }
                    : move
            );
    
            setSelectedPokemon({
                ...fixedPokemon,
                moves: normalizedMoves,
                stats: details.stats,
                effectiveness: details.effectiveness,
            });
        } catch (error: any) {
            console.error("[ERROR] Fetching Pokémon details:", error.message);
    
            setSelectedPokemon({
                ...fixedPokemon,
                moves: [{ name: "No moves found", type: "Unknown", power: undefined }],
                stats: { hp: -1, attack: -1, defense: -1, speed: -1 },
                effectiveness: {
                    defensive: { strong: [], weak: [], immune: [] },
                    offensive: { strong: [], weak: [], immune: [] },
                },
            });
        } finally {
            setIsLoadingMoves(false);
        }
    };               

    const handleMoveSelection = (move: Move) => {
        // Check if the move is already selected
        if (selectedMoves.some((selectedMove) => selectedMove.name === move.name)) {
            // Deselect the move
            setSelectedMoves(
                selectedMoves.filter((selectedMove) => selectedMove.name !== move.name)
            );
        } else if (selectedMoves.length < 4) {
            // Select the move
            setSelectedMoves([...selectedMoves, move]);
        }
    };    

    const handleConfirm = async () => {
        setShowConfirmPopup(false);

        // Fetch a random opponent
        const randomOpponent = filteredPokemonList[Math.floor(Math.random() * filteredPokemonList.length)];

        let opponentDetails = {
            moves: [],
            stats: { hp: 50, attack: 50, defense: 50, speed: 50 },
            effectiveness: { strong: [], weak: [], immune: [] },
        };

        try {
            const details = await fetchPokemonDetails(randomOpponent);
        
            opponentDetails = {
                moves: details.moves,
                stats: details.stats,
                effectiveness: {
                    strong: details.effectiveness.offensive.strong || [],
                    weak: details.effectiveness.offensive.weak || [],
                    immune: details.effectiveness.offensive.immune || [],
                },
            };
        } catch (error: any) {
            console.error('[ERROR] Fetching opponent Pokémon details:', error?.message || error);
        }        

        const updatedPokemon = {
            ...selectedPokemon,
            selectedMoves: selectedMoves,
        };

        console.log('Navigating to BattleArena with player and opponent data:', updatedPokemon, opponentDetails);

        navigate('/battle', {
            state: {
                playerPokemon: updatedPokemon,
                opponentPokemon: {
                    ...randomOpponent,
                    ...opponentDetails,
                },
            },
        });
    };

    const handleClosePopup = () => {
        setShowConfirmPopup(false);
        setSelectedMoves([]);
    };

    return (
        <div className="selection-screen">
            <button className="back-arrow" onClick={() => navigate('/menu')} aria-label="Back to Menu">
                &larr;
            </button>

            <h1>Select Your Pokémon</h1>
            <div id="dropdown-container">
                <label htmlFor="generation-select">Select Generation</label>
                <select
                    id="generation-select"
                    className="styled-select"
                    value={selectedGeneration}
                    onChange={(e) => setSelectedGeneration(parseInt(e.target.value, 10))}
                >
                    {Object.keys(GENERATION_RANGES).map((gen) => (
                        <option key={gen} value={gen}>
                            Generation {gen}
                        </option>
                    ))}
                </select>
            </div>

            {loading ? (
                <div className="loading-container">
                    <div className="loading-spinner"></div>
                    <p>Loading Pokémon...</p>
                </div>
            ) : (
                <div id="pokemon-selection">
                    {filteredPokemonList.map((pokemon) => (
                        <div
                            key={`${pokemon.id}-${pokemon.name}`}
                            className="pokemon-option"
                            onClick={() => {
                                console.log('[DEBUG] Pokémon Clicked:', pokemon); // Log the Pokémon object on click
                                handleSelectPokemon(pokemon); // Pass it to the handler
                            }}
                        >
                            <img
                                src={pokemon.image_url}
                                alt={pokemon.name}
                                onError={(e) => {
                                    console.warn(`[WARNING] Failed to load image for Pokémon: ${pokemon.name}`); // Log if the image fails to load
                                    (e.target as HTMLImageElement).src = CONFIG.FALLBACK_IMAGE;
                                }}
                            />
                        </div>
                    ))}
                </div>
            )}

            {showConfirmPopup && selectedPokemon && (
                <>
                    <div className="confirm-overlay" onClick={handleClosePopup}></div>
                    <div className="confirm-popup">
                    <div className="popup-left">
                        <img
                            src={selectedPokemon.image_url || CONFIG.FALLBACK_IMAGE}
                            alt={selectedPokemon.name || "Unknown"}
                            className="popup-sprite"
                        />
                        <div className="popup-moves">
                            {isLoadingMoves ? (
                                <div className="loading-container">
                                    <div className="loading-spinner"></div>
                                </div>
                            ) : selectedPokemon.moves && selectedPokemon.moves.length > 0 ? (
                                selectedPokemon.moves.map((move, index) => {
                                    // Safely cast each move to the Move type or handle fallback
                                    const moveData: Move =
                                        typeof move === "string"
                                            ? { name: move, type: "Unknown", power: undefined }
                                            : move;

                                    // Function to capitalize the first letter of each word
                                    const capitalizeWords = (text: string) => 
                                        text
                                            .split(" ")
                                            .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
                                            .join(" ");

                                    return (
                                        <div
                                            key={index}
                                            className={`popup-move ${
                                                selectedMoves.some((selectedMove) => selectedMove.name === moveData.name)
                                                    ? "selected"
                                                    : ""
                                            }`}
                                            onClick={() => handleMoveSelection(moveData)}
                                        >
                                            <strong>{capitalizeWords(moveData.name)}</strong>
                                        </div>
                                    );
                                })
                            ) : (
                                <p className="no-moves">No moves available :(</p>
                            )}
                        </div>
                        {selectedMoves.length !== 4 && !isLoadingMoves && (
                            <p className="select-moves-message">
                                Please select {4 - selectedMoves.length} more move(s).
                            </p>
                        )}
                    </div>
                        <div className="popup-right">
                            {isLoadingMoves ? (
                                <div className="loading-container">
                                    <div className="loading-spinner"></div>
                                </div>
                            ) : (
                                <>
                                    <h3>{selectedPokemon.name.charAt(0).toUpperCase() + selectedPokemon.name.slice(1)}</h3>
                                    <div className="popup-stats">
                                        <div className="stat-row">
                                            <span className="stat-label">HP:</span>
                                            <span className="stat-value">{selectedPokemon.stats.hp ?? "N/A"}</span>
                                        </div>
                                        <div className="stat-row">
                                            <span className="stat-label">Attack:</span>
                                            <span className="stat-value">{selectedPokemon.stats.attack ?? "N/A"}</span>
                                        </div>
                                        <div className="stat-row">
                                            <span className="stat-label">Defense:</span>
                                            <span className="stat-value">{selectedPokemon.stats.defense ?? "N/A"}</span>
                                        </div>
                                        <div className="stat-row">
                                            <span className="stat-label">Speed:</span>
                                            <span className="stat-value">{selectedPokemon.stats.speed ?? "N/A"}</span>
                                        </div>
                                        <div className="stat-row">
                                            <span className="stat-label">Strong Against:</span>
                                            <span className="stat-value">
                                                {selectedPokemon.effectiveness?.defensive?.strong?.length
                                                    ? selectedPokemon.effectiveness.defensive.strong
                                                        .map((type) => type.charAt(0).toUpperCase() + type.slice(1))
                                                        .join(', ')
                                                    : "N/A"}
                                            </span>
                                        </div>
                                        <div className="stat-row">
                                            <span className="stat-label">Weak Against:</span>
                                            <span className="stat-value">
                                                {selectedPokemon.effectiveness?.defensive?.weak?.length
                                                    ? selectedPokemon.effectiveness.defensive.weak
                                                        .map((type) => type.charAt(0).toUpperCase() + type.slice(1))
                                                        .join(', ')
                                                    : "N/A"}
                                            </span>
                                        </div>
                                        <div className="stat-row">
                                            <span className="stat-label">Immune To:</span>
                                            <span className="stat-value">
                                                {selectedPokemon.effectiveness?.defensive?.immune?.length
                                                    ? selectedPokemon.effectiveness.defensive.immune
                                                        .map((type) => type.charAt(0).toUpperCase() + type.slice(1))
                                                        .join(', ')
                                                    : "N/A"}
                                            </span>
                                        </div>
                                    </div>
                                </>
                            )}
                            <div className="popup-buttons">
                                <button
                                    className="confirm-btn"
                                    onClick={handleConfirm}
                                    disabled={selectedMoves.length !== 4}
                                >
                                    Confirm
                                </button>
                                <button className="cancel-btn" onClick={handleClosePopup}>Cancel</button>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

export default SelectionScreen;
