import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import MainMenu from './components/MainMenu';
import LoginRegister from './components/LoginRegister';
import SelectionScreen from './components/SelectionScreen';
import BattleArena from './components/BattleArena';
import Settings from './components/Settings';
import './styles/main.css'; // Global CSS

const App: React.FC = () => {
    const isLoggedIn = localStorage.getItem('isLoggedIn') === 'true';
    const username = localStorage.getItem('username') || ''; // Retrieve username from localStorage

    return (
        <Router>
            <Routes>
                <Route path="/" element={<LoginRegister />} />
                <Route path="/menu" element={isLoggedIn ? <MainMenu /> : <Navigate to="/" />} />
                <Route path="/pokemon-select" element={isLoggedIn ? <SelectionScreen /> : <Navigate to="/" />} />
                <Route path="/battle" element={isLoggedIn ? <BattleArena /> : <Navigate to="/" />} />
                <Route path="/reset-password" element={<LoginRegister />} />
                <Route path="/settings" element={isLoggedIn ? <Settings username={username} /> : <Navigate to="/" />} />
            </Routes>
        </Router>
    );
};

export default App;
