import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import '../styles/battle-arena.css';

interface Move {
    name: string;
    type?: string;
    power?: number;
}

interface Pokemon {
    id: number;
    name: string;
    stats: { hp: number; attack: number; defense: number; speed: number };
    image_url: string;
    back_image_url?: string;
    moves: Move[];
    selectedMoves?: Move[];
    effectiveness?: { strong: string[]; weak: string[]; immune: string[] };
}

// Utility function to capitalize the first letter of a string
const capitalize = (str: string) => str.charAt(0).toUpperCase() + str.slice(1);

const BattleArena: React.FC = () => {
    const location = useLocation();
    const navigate = useNavigate();

    // Player and opponent data passed from SelectionScreen.tsx
    const playerPokemon: Pokemon = location.state?.playerPokemon;
    const opponentPokemon: Pokemon = location.state?.opponentPokemon;

    // State variables
    const [playerHP, setPlayerHP] = useState(playerPokemon?.stats.hp || 0);
    const [opponentHP, setOpponentHP] = useState(opponentPokemon?.stats.hp || 0);
    const [moveText, setMoveText] = useState('');
    const [menuState, setMenuState] = useState('main'); // 'main' or 'fight'
    const [isLoading, setIsLoading] = useState(true); // Loading state

    // Ensure both player and opponent data are present
    useEffect(() => {
        if (!playerPokemon || !opponentPokemon) {
            console.error('Missing player or opponent data. Redirecting to selection screen.');
            navigate('/pokemon-select');
        } else {
            setTimeout(() => setIsLoading(false), 3000); // Simulate loading delay
        }
    }, [navigate, playerPokemon, opponentPokemon]);

    // Player's attack logic
    const attack = (move: Move) => {
        if (!opponentPokemon || !playerPokemon) return;
        setMoveText(`Player's ${capitalize(playerPokemon.name)} used ${move.name}!`);

        const damage = move.power ? Math.floor(move.power * Math.random()) : Math.floor(Math.random() * 20) + 10;
        setOpponentHP((hp) => Math.max(0, hp - damage));

        setTimeout(() => {
            if (opponentHP - damage <= 0) {
                setMoveText(`${capitalize(opponentPokemon.name)} fainted! You win!`);
                return;
            }
            opponentAttack();
        }, 1000);
    };

    // Opponent's attack logic
    const opponentAttack = () => {
        if (!opponentPokemon || !playerPokemon) return;

        const randomMove = opponentPokemon.moves[Math.floor(Math.random() * opponentPokemon.moves.length)] || {
            name: 'Tackle',
            power: 10,
        };
        setMoveText(`Opponent's ${capitalize(opponentPokemon.name)} used ${randomMove.name}!`);

        const damage = randomMove.power ? Math.floor(randomMove.power * Math.random()) : Math.floor(Math.random() * 20) + 10;
        setPlayerHP((hp) => Math.max(0, hp - damage));
    };

    return (
        <div id="battle-screen">
            {isLoading && (
                <div id="loading-overlay">
                    <div id="loading-spinner"></div>
                    <p>Starting Battle...</p>
                </div>
            )}

            {!isLoading && (
                <div id="battle-arena">
                    {/* Opponent Info */}
                    {opponentPokemon && (
                        <div id="opponent-info">
                            <div className="pokemon-info-box">
                                <div className="pokemon-name">{capitalize(opponentPokemon.name)}</div>
                                <div className="level">Lv {opponentPokemon.stats.hp}</div>
                                <div className="hp-bar-wrapper">
                                    <span className="hp-label">HP</span>
                                    <div className="hp-bar-container">
                                        <div
                                            className="hp-bar"
                                            style={{ width: `${(opponentHP / opponentPokemon.stats.hp) * 100}%` }}
                                        ></div>
                                    </div>
                                </div>
                                <div className="hp-counter">
                                    {opponentHP}/{opponentPokemon.stats.hp} HP
                                </div>
                            </div>
                        </div>
                    )}

                    {/* Player Info */}
                    {playerPokemon && (
                        <div id="player-info">
                            <div className="pokemon-info-box">
                                <div className="pokemon-name">{capitalize(playerPokemon.name)}</div>
                                <div className="level">Lv {playerPokemon.stats.hp}</div>
                                <div className="hp-bar-wrapper">
                                    <span className="hp-label">HP</span>
                                    <div className="hp-bar-container">
                                        <div
                                            className="hp-bar"
                                            style={{ width: `${(playerHP / playerPokemon.stats.hp) * 100}%` }}
                                        ></div>
                                    </div>
                                </div>
                                <div className="hp-counter">
                                    {playerHP}/{playerPokemon.stats.hp} HP
                                </div>
                            </div>
                        </div>
                    )}

                    {/* Arena Content */}
                    {/* Player's Pokémon */}
                    <div id="player-area">
                        <img
                            src={playerPokemon?.back_image_url || playerPokemon?.image_url}
                            alt={playerPokemon?.name}
                            className="pokemon-image-player"
                            style={{
                                animation: 'slide-in-left 1.5s ease-out forwards',
                            }}
                        />
                    </div>

                    {/* Opponent's Pokémon */}
                    <div id="opponent-area">
                        <img
                            src={opponentPokemon?.image_url}
                            alt={opponentPokemon?.name}
                            className="pokemon-image-opponent"
                            style={{
                                animation: 'slide-in-right 2s ease-out forwards',
                            }}
                        />
                    </div>

                    {/* Action Prompt Box */}
                    {menuState === 'main' && (
                        <div id="action-prompt-box">
                            <p className="action-prompt-text">
                                What will {capitalize(playerPokemon?.name || 'your Pokémon')} do?
                            </p>
                        </div>
                    )}

                    {/* Move Text */}
                    <div id="battle-selection-arrow-container">
                        <p id="battle-move-text">{moveText}</p>
                    </div>

                    {/* Battle UI */}
                    <div id="battle-ui">
                        {menuState === 'main' && (
                            <div id="battle-selection-menu">
                                <div className="battle-menu-option" onClick={() => setMenuState('fight')}>
                                    FIGHT
                                </div>
                                <div className="battle-menu-option">BAG</div>
                                <div className="battle-menu-option">POKéMON</div>
                                <div className="battle-menu-option">RUN</div>
                            </div>
                        )}
                        {menuState === 'fight' && (
                            <>
                                <div id="battle-selection-menu">
                                    <div className="moves-container">
                                        {playerPokemon?.selectedMoves?.map((move, index) => (
                                            <div
                                                key={index}
                                                className="battle-menu-option move-button"
                                                onClick={() => {
                                                    attack(move);
                                                    setMenuState('main');
                                                }}
                                            >
                                                {move.name.toUpperCase()}
                                            </div>
                                        ))}
                                    </div>
                                    <div
                                        className="battle-menu-option back-button"
                                        onClick={() => setMenuState('main')}
                                    >
                                        BACK
                                    </div>
                                </div>
                                <div id="secondary-battle-menu">
                                    <p>Hover over a move to view details here.</p>
                                </div>
                            </>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

export default BattleArena;
