import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles/settings.css';

interface SettingsProps {
    username: string;
}

const Settings: React.FC<SettingsProps> = ({ username }) => {
    const navigate = useNavigate(); // Using navigate to handle routing

    return (
        <div className="settings-page">
            <button
                className="settings-back-arrow"
                onClick={() => navigate('/menu')} // Navigate back to the menu
                aria-label="Back to Menu"
            >
                &larr;
            </button>
            <div className="settings-header">Settings</div>
            <div className="profile-section">
                <h1 className="profile-header">Profile Information</h1>
                <div className="profile-item">
                    <h2>Username:</h2>
                    <p>{username || 'No username available'}</p>
                </div>
            </div>
        </div>
    );
};

export default Settings;
